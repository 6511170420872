import { RewardEnum } from '@/js/quests/dashboard/types/enums';
import {
    LuckyDrawRewardsApiResponse,
    LuckyDrawWinnersApiResponse,
    PaginationType,
} from '@/js/quests/dashboard/types/luckyDrawWinner';
import { dashboardApiEndpoints } from '@/js/quests/dashboard/constants/dashboardApiEndpoints';
import useAxiosRepository from '@/js/repositories/BaseRepository';

const axiosRepo = useAxiosRepository();

export const getOwnerLuckyDrawWinnerRewards = async (slug: string, showError = true): Promise<LuckyDrawRewardsApiResponse> => {
    const api = dashboardApiEndpoints.OWNER_LUCKY_DRAW_WINNER_REWARDS(slug);

    return axiosRepo.get(api, { showError });
};

export const getOwnerLuckyDrawWinners = async ({ selector, type, showError = true }: {
    selector: string,
    type: RewardEnum,
    showError?: boolean
}, { page, perPage }: PaginationType = {}): Promise<LuckyDrawWinnersApiResponse> => {
    const api = type === RewardEnum.ALL
        ? dashboardApiEndpoints.OWNER_LUCKY_DRAW_WINNERS_ALL(selector)
        : dashboardApiEndpoints.OWNER_LUCKY_DRAW_WINNERS_BY_REWARD(selector);

    const params = {
        page: page.toString(),
        perPage: perPage.toString(),
    };

    const joinedParams = new URLSearchParams(params).toString();

    return axiosRepo.get(`${api}?${joinedParams}`, { showError });
};

export const getLuckyDrawWinners = async (slug: string, { page, perPage }: PaginationType = {}, showError = true): Promise<LuckyDrawWinnersApiResponse> => {
    const api = dashboardApiEndpoints.LUCKY_DRAW_WINNERS(slug);

    const params = {
        page: page.toString(),
        perPage: perPage.toString(),
    };

    const joinedParams = new URLSearchParams(params).toString();

    return axiosRepo.get(`${api}?${joinedParams}`, { showError });
};

export const getOwnerLuckyDrawWinnersFileData = async ({ selector, type, showError = true }: {
    selector: string,
    type: RewardEnum,
    showError?: boolean
}): Promise<Blob> => {
    const api = type === RewardEnum.ALL
        ? dashboardApiEndpoints.OWNER_LUCKY_DRAW_WINNERS_ALL(selector)
        : dashboardApiEndpoints.OWNER_LUCKY_DRAW_WINNERS_BY_REWARD(selector);

    return axiosRepo.get(`${api}?is_csv=true`, { params: { responseType: 'blob' }, showError });
};

export const postLuckyDrawRestart = async (slug: string, showError = true, showSuccess = true) => {
    const api = dashboardApiEndpoints.LUCKY_DRAW_RESTART(slug);

    await axiosRepo.post(api, null, { showError, showSuccess });
};
