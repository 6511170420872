import { showToast } from '@/js/helpers/toastHelpers';
import { getTrans } from '@/js/helpers/getTrans';

export function useCsvFileDownload(data: Blob, fileName: string) {
    try {
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const fileUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(fileUrl);

        showToast({
            message: getTrans('quests-dashboard.downloadWinnerListSuccess', 'Winner list downloaded'),
            type: 'success',
        });
    } catch (error) {
        showToast({
            message: getTrans('quests-dashboard.downloadWinnerListError', 'Error downloading winner list'),
        });
    }
}
