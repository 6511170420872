import { defineStore } from 'pinia';
import { RewardEnum } from '@/js/quests/dashboard/types/enums';
import {
    LuckyDrawWinnersDownloadActionType,
    LuckyDrawWinnersStateType,
} from '@/js/quests/dashboard/types/luckyDrawWinner';
import {
    getLuckyDrawWinners,
    getOwnerLuckyDrawWinnerRewards,
    getOwnerLuckyDrawWinners,
    getOwnerLuckyDrawWinnersFileData,
    postLuckyDrawRestart,
} from '@/js/quests/dashboard/services/luckyDrawWinnerApi';
import { useCsvFileDownload } from '@/js/quests/dashboard/composables/useCsvFileDownload';

export const useLuckyDrawWinnerStore = defineStore({
    id: 'luckyDrawWinner',
    state: (): LuckyDrawWinnersStateType => ({
        isOwnerData: false,
        rewards: {
            list: [],
            isLoading: false,
            isFetchCompleted: false,
        },
        winners: {
            list: [],
            isLoading: false,
            isDownloading: false,
            isFetchCompleted: false,
            currentPage: 1,
            isLastPage: false,
        },
    }),
    actions: {
        resetLuckyDrawWinnerState() {
            const { isOwnerData } = this;
            this.$reset();
            this.isOwnerData = isOwnerData;
        },
        setShowOwnerData() {
            this.isOwnerData = true;
        },
        setShowRegularData() {
            this.isOwnerData = false;
        },
        increaseWinnersCurrentPage() {
            this.winners.currentPage += 1;
        },
        resetWinnersCurrentPage() {
            this.winners.currentPage = 1;
        },
        async fetchOwnerLuckyDrawWinnerRewards(slug: string) {
            this.rewards.isLoading = true;

            const response = await getOwnerLuckyDrawWinnerRewards(slug);

            this.rewards.list = response.data;
            this.rewards.isLoading = false;
            this.rewards.isFetchCompleted = true;
        },
        async fetchOwnerLuckyDrawWinners(selector: string, type: RewardEnum) {
            this.winners.isLoading = true;

            const response = await getOwnerLuckyDrawWinners({ selector, type }, { page: this.winners.currentPage, perPage: 20 });

            if (this.winners.currentPage === 1) {
                this.winners.list = response.data;
            } else {
                this.winners.list = [...this.winners.list, ...response.data];
            }

            this.winners.isLastPage = this.winners.currentPage === response.meta.last_page;
            this.winners.isLoading = false;
            this.winners.isFetchCompleted = true;
        },
        async fetchLuckyDrawWinners(slug: string) {
            this.winners.isLoading = true;

            const response = await getLuckyDrawWinners(slug, { page: this.winners.currentPage, perPage: 20 });

            if (this.winners.currentPage === 1) {
                this.winners.list = response.data;
            } else {
                this.winners.list = [...this.winners.list, ...response.data];
            }

            this.winners.isLastPage = this.winners.currentPage === response.meta.last_page;
            this.winners.isLoading = false;
            this.winners.isFetchCompleted = true;
        },
        async downloadOwnerLuckyDrawWinners({
            selector, type, missionTitle, rewardTitle, date,
        } : LuckyDrawWinnersDownloadActionType) {
            this.winners.isDownloading = true;

            const response = await getOwnerLuckyDrawWinnersFileData({ selector, type });
            const fileName = `${missionTitle} - ${rewardTitle} - ${date} winners`;

            useCsvFileDownload(response, fileName);

            this.winners.isDownloading = false;
        },
        async restartLuckyDraw(slug: string) {
            await postLuckyDrawRestart(slug);
        },
    },
});
