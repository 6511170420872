import {
 onMounted, onUnmounted, ref, Ref,
} from 'vue';
import { HtmlIds } from '../constants/htmlIds';

export const useMobileFooterAnimation = (isPlayButtonVisible: Ref<boolean>) => {
    const MobileFooterRef = ref<HTMLElement>(null);

    const triggerPlayButtonAnimation = () => {
        const playBtn: HTMLElement = document.querySelector('.play-btn');
        if (playBtn) {
            playBtn.style.top = '160px';
        }

        isPlayButtonVisible.value = false;
        setTimeout(() => {
            isPlayButtonVisible.value = true;
        }, 500);
    };

    const moveFooterIcons = () => {
        if (MobileFooterRef.value) {
            MobileFooterRef.value.classList.toggle('mobile-footer--animated');
        }
    };

    onMounted(() => {
        MobileFooterRef.value = document.getElementById(HtmlIds.MOBILE_FOOTER_ID);

        moveFooterIcons();
        triggerPlayButtonAnimation();
    });

    onUnmounted(() => {
        moveFooterIcons();
    });
};
